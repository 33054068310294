import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import CtaSection from "./../components/CtaSection";
import NewsletterSection from "./../components/NewsletterSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        title="Turn Ideas into Crystal-Clear Documents"
        subtitle="Product Pilot helps you write with impact, get your whole team aligned, and save time."
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <FeaturesSection
        title="Level Up Your Product Strategy"
        subtitle="Transform your product vision into reality with AI-powered clarity and impact."
        strapline="Effortless communication for product managers"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection
        title={
          <>
            Join the Beta <span className="text-blue-600">Today</span>!
          </>
        }
        subtitle="Join our beta community and help us build a tool that empowers product managers"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      {/* <NewsletterSection
        title="Stay Ahead of the Curve"
        subtitle="Get the latest updates on our LLM copilot, product management insights, and exclusive resources."
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      /> */}
    </>
  );
}

export default IndexPage;
