import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function FeaturesSection(props) {
  const features = [
    {
      title: "Build documents from existing content",
      description: "Add context to the doc you're working on and use it for prompting.",
    },
    {
      title: "LLM copilot where you need it",
      description: "Share context with your current document and add data directly from the LLM output.",
    },
    {
      title: "Prompted like a product manager",
      description: "Get an experienced product copilot to help you think through challenges, define metrics, and improve your writing.",
    },
    {
      title: "Document management",
      description: "Save documents, edit in markdown.",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="p-6 rounded-lg border border-gray-200 bg-white shadow-md"
            >
              <h4 className="text-xl font-semibold mb-3">{feature.title}</h4>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection;
