import React, { useState, useEffect, useCallback, useContext } from "react";
import { updateDocument } from "../util/db";
import { debounce } from "lodash";
import SideMenu from "./SideMenu";
import { CanvasContext } from "./CanvasContext";

function ContextArea(props) {
  const documentContext = props.documentContext;
  const setDocumentContext = props.setDocumentContext;
  const contextDetails = useContext(CanvasContext);
  const documentDetails = props.documentDetails;
  const updateDocumentDetails = props.updateDocumentDetails;
  const [ writingGoal, setWritingGoal ] = useState('');

  useEffect(() => {
    setWritingGoal(documentDetails.goals);
  }, [documentDetails, documentContext]);

  const debouncedUpdate = useCallback(debounce((goal) => {
    updateDocument(props.documentDetails.id, 'goals', goal);
    updateDocumentDetails('goals', goal)
  }, 300), [props.documentDetails.id]);

  function handleUpdateGoal(goal) {
    setWritingGoal(goal);
    debouncedUpdate(goal);
  }


  return (
    <div className="h-12 overflow-hidden flex flex-col justify-between">
      <div className="h-full mb-2 flex flex-col">
        <SideMenu
          handleDeleteContext={props.handleDeleteContext}
          documentDetails={documentDetails}
          documentContext={props.documentContext}
          updateDocumentDetails={updateDocumentDetails}
          contextDetails={props.contextDetails}
          />
      </div>
    </div>
  );
}

export default ContextArea;
