import React from "react";

const SuggestedPrompts = ({ prompts, handlePromptClick }) => {
  const chunk = (array, size) => {
    let result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };
  const chunkedPrompts = chunk(prompts, 2);

  return (

      <div className="mx-auto flex flex-wrap justify-center">
        {chunkedPrompts.map((promptChunk, index) => (
          <div className="flex w-full justify-between" key={index}>
            {promptChunk.map((prompt, i) => (
              <div
                key={i}
                className="w-1/2 m-2 cursor-pointer rounded-xl bg-white p-4 text-sm shadow-xl hover:shadow-2xl"
                onClick={() => {
                  handlePromptClick(prompt);
                }}
                onMouseDown={(e) => e.preventDefault()}
              >
                {prompt}
              </div>
            ))}
          </div>
        ))}
      </div>
  );
};

export default SuggestedPrompts;
