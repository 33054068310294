import { useEffect } from 'react';
import { useEditor } from '@tiptap/react';
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import Placeholder from '@tiptap/extension-placeholder';
import { Markdown } from "tiptap-markdown";
import SlashCommand from '../extensions/SlashCommand/SlashCommand';
import emojiSuggestion from '../extensions/EmojiSuggestion/suggestion';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';

export const useBlockEditor = (saveDataCallback) => {

    const extensions = [
        StarterKit, 
        Markdown, 
        CharacterCount, 
        Placeholder.configure({ placeholder: 'Write here' }), 
        SlashCommand,
        Emoji.configure({
            enableEmoticons: true,
            suggestion: emojiSuggestion,
            emojis: gitHubEmojis
          })
        ];

    const editor = useEditor({
        extensions,
        onUpdate: ({ editor }) => {
            saveDataCallback(editor.getJSON());
        },
    });


    useEffect(() => {
        if (editor && editor.isEmpty) {
            editor.commands.setContent(/* Your initial content */);
        }
    }, [editor]);

    return { editor }; 
};
