import React, { useEffect, useState } from "react";
import ContextArea from "./ContextArea";
import ChatArea from "./ChatArea";
import { Separator } from "./ui/separator";

function ChatContext(props) {
    return (
    <div className="flex flex-col overflow-y-hidden w-2/6 pr-4 pt-4 pb-4">
      <ContextArea
        handleDeleteContext={props.handleDeleteContext}
        documentDetails={props.documentDetails}
        updateDocumentDetails={props.updateDocumentDetails}
        documentContext={props.documentContext}
        setDocumentContext={props.setDocumentContext}
        contextDetails={props.contextDetails}
        />
      <Separator />
      <ChatArea 
      editor={props.editor} 
      documentDetails={props.documentDetails} 
      context={props.documentDetails.goals}
      contextData={props.contextData} />
    </div>
  );
}

export default ChatContext;