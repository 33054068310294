import React, { useCallback, useEffect, useRef, useState } from 'react'

import { CommandButton } from './CommandButton'
import { Card } from '../../components/ui/card'
import { Icon } from '../../components/ui/icon'
import { Button } from '../../components/ui/button'

export const MenuList = React.forwardRef((props, ref) => {
  const scrollContainer = useRef(null)
  const activeItem = useRef(null)
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0)
  const [selectedCommandIndex, setSelectedCommandIndex] = useState(0)

  // Anytime the groups change, i.e. the user types to narrow it down, we want to
  // reset the current selection to the first menu item
  useEffect(() => {
    setSelectedGroupIndex(0)
    setSelectedCommandIndex(0)
  }, [props.items])

  const selectItem = useCallback(
    (groupIndex, commandIndex) => {
      const command = props.items[groupIndex].commands[commandIndex]
      props.command(command)
    },
    [props],
  )

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowDown') {
        if (!props.items.length) {
          return false
        }

        const commands = props.items[selectedGroupIndex].commands

        let newCommandIndex = selectedCommandIndex + 1
        let newGroupIndex = selectedGroupIndex

        if (commands.length - 1 < newCommandIndex) {
          newCommandIndex = 0
          newGroupIndex = selectedGroupIndex + 1
        }

        if (props.items.length - 1 < newGroupIndex) {
          newGroupIndex = 0
        }

        setSelectedCommandIndex(newCommandIndex)
        setSelectedGroupIndex(newGroupIndex)

        return true
      }

      if (event.key === 'ArrowUp') {
        if (!props.items.length) {
          return false
        }

        let newCommandIndex = selectedCommandIndex - 1
        let newGroupIndex = selectedGroupIndex

        if (newCommandIndex < 0) {
          newGroupIndex = selectedGroupIndex - 1
          newCommandIndex = props.items[newGroupIndex]?.commands.length - 1 || 0
        }

        if (newGroupIndex < 0) {
          newGroupIndex = props.items.length - 1
          newCommandIndex = props.items[newGroupIndex].commands.length - 1
        }

        setSelectedCommandIndex(newCommandIndex)
        setSelectedGroupIndex(newGroupIndex)

        return true
      }

      if (event.key === 'Enter') {
        if (!props.items.length || selectedGroupIndex === -1 || selectedCommandIndex === -1) {
          return false
        }

        selectItem(selectedGroupIndex, selectedCommandIndex)

        return true
      }

      return false
    },
  }))

  useEffect(() => {
    if (activeItem.current && scrollContainer.current) {
      const offsetTop = activeItem.current.offsetTop
      const offsetHeight = activeItem.current.offsetHeight

      scrollContainer.current.scrollTop = offsetTop - offsetHeight
    }
  }, [selectedCommandIndex, selectedGroupIndex])

  const createCommandClickHandler = useCallback(
    (groupIndex, commandIndex) => {
      return () => {
        selectItem(groupIndex, commandIndex)
      }
    },
    [selectItem],
  )

  if (!props.items.length) {
    return null
  }

  return (
    <div ref={scrollContainer} className="text-black max-h-[min(80vh,24rem)] overflow-auto mb-8 p-2">
      <Card className={`grid gap-0.5 p-4`}>  {/* Apply grid to Card */}
        {props.items.map((group, groupIndex) => (
          <React.Fragment key={`${group.title}-wrapper`}>
            <div
              className="text-neutral-500 text-[0.65rem] col-[1/-1] mx-2 mt-4 font-semibold tracking-wider select-none uppercase first:mt-0.5"
              key={`${group.title}`}
              style={{ gridRow: `span ${group.commands.length + 1}` }} // Span group title + commands
            >
              {group.title}
            </div>
            {group.commands.map((command, commandIndex) => (
              <Button
              key={`${command.label}`}
              variant={selectedGroupIndex === groupIndex && selectedCommandIndex === commandIndex ? "active" : "ghost"}
              //isActive={selectedGroupIndex === groupIndex && selectedCommandIndex === commandIndex}
              onClick={createCommandClickHandler(groupIndex, commandIndex)}
              className="justify-start px-0"
            >
              <div className="flex items-center ml-2">
                <Icon name={command.iconName} className="mr-1" />
                {command.label}
              </div> 
            </Button>
            ))}
          </React.Fragment>
        ))}
      </Card>
    </div>
  );
})

MenuList.displayName = 'MenuList'

export default MenuList
