import React, { useEffect } from "react";
import { useAuth } from "../util/auth";
import { useDocumentsByOwner, deleteDocument } from "../util/db";
import FormAlert from "./FormAlert";
import { Button } from "./ui/button";
import { createDocument } from "../util/db";

function DocumentsList(props) {
  const updateDocumentDetails = props.updateDocumentDetails;
  const documentDetails = props.documentDetails;

  const auth = useAuth();

  const handleFileSelect = async (fileId) => {
    updateDocumentDetails("id", fileId);
  };

  const handleAddDocument = () => {

    const data = {name: 'New document', template: '', audience: '', goals:''}
    const query = createDocument({ owner: auth.user.uid, ...data });

    query
      .then(() => {
        const emptyTiptapDoc = {
          type: "doc",
          content: []
        };
        
        return;
      })
      .catch((error) => {
        // Hide pending indicator
        // setPending(false);
        // Show error alert message
        // setFormAlert({
        //   type: "error",
        //   message: error.message,
        // });
      });
  };

  const {
    data: documents,
    status: documentsStatus,
    error: documentsError,
  } = useDocumentsByOwner(auth.user.uid);

  useEffect(() => {
    if (!documents || documents.length === 0) return;
    updateDocumentDetails("id", documents[0].id);
  }, [documents?.length]);

  const handleDelete = async (id) => {
    try {
      await deleteDocument(id);

      updateDocumentDetails(documents[0].document_id);
    } catch (error) {
      console.error("Error deleting file:", error);
      // Consider displaying an error message to the user
    }
  };

  const documentsAreEmpty = !documents || documents.length === 0;

  return (
    <div className="flex h-screen w-1/6 flex-col overflow-y-hidden bg-gray-100 pl-3 pt-4">
      <div className="mb-2 flex items-center justify-between">
        <h1 className="text-md font-bold text-slate-700"> Documents </h1>
        <button
          onClick={(e) => {
            e.preventDefault();
            auth.signout();
          }}
          className="text-sm text-slate-700"
        >
          {" "}
          Log out{" "}
        </button>
      </div>
      {documentsError && (
        <div className="mb-4">
          <FormAlert type="error" message={documentsError.message} />
        </div>
      )}
      {(documentsStatus === "loading" || documentsAreEmpty) && (
        <div className="p-8">
          {documentsStatus === "loading" && <>Loading...</>}

          {documentsStatus !== "loading" && documentsAreEmpty && (
            <>Nothing yet. Click the button to add your first item.</>
          )}
        </div>
      )}
      {documentsStatus != "loading" && documents && documents.length > 0 && (
        <ul className="p-1 overflow-y-scroll [&::-webkit-scrollbar]:hidden mb-10">
          {documents.map((document, index) => (
            <div
              className={`cursor-pointer rounded-3xl pl-1 hover:bg-gray-200 ${documentDetails?.id === document.id ? "selected bg-gray-200" : ""}`}
            >
              <li
                key={document.document_id}
                onClick={() => handleFileSelect(document.id)}
                className="p-2 pl-2 text-sm text-slate-700 overflow-ellipsis whitespace-nowrap overflow-hidden hover:bg-gray-20"
              >
                {document.name}
                {/* <button onClick={() => handleDelete(document.id)}>Delete</button>  */}
              </li>
            </div>
          ))}
        </ul>
      )}
      <div className="absolute bottom-0 mb-2 flex items-center justify-center p-4">
        <Button onClick={handleAddDocument}>
          Add Document
        </Button>
      </div>
    </div>
  );
}

export default DocumentsList;
