// NarrativeMakerApp.js
import React, { useState, useEffect, useCallback } from "react";
import { requireAuth } from "../util/auth";
import DocumentsList from "../components/DocumentsList";
import DocumentPage from "../components/DocumentPage";
import ChatContext from "../components/ChatContext";
import { loadDocumentContext, loadDocumentData, loadDocumentDetails, updateDocument, saveDocumentData } from "../util/db";
import { useEditor } from "@tiptap/react";
import { useBlockEditor } from "../hooks/useBlockEditor";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import Placeholder from '@tiptap/extension-placeholder'
import { Markdown } from "tiptap-markdown";
import { useAuth } from "../util/auth";
import { useDocumentsByOwner } from "../util/db";
import { deleteDocumentContext, createDocumentContext } from "../util/db";
import { ToastAction } from "../components/ui/toast";
import { useToast } from "../components/ui/use-toast";
import { CanvasContext } from "../components/CanvasContext";
import { set } from "react-hook-form";
import { debounce } from "lodash";


const CanvasPage = () => {
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [documentDetails, setDocumentDetails] = useState({});
  const [documentContext, setDocumentContext] = useState([]);
  const [contextDetails, setContextDetails] = useState({});
  const [contextData, setContextData] = useState({});
  const [contextUpdated, setContextUpdated] = useState(false);
  const [saveState, setSaveState] = useState("idle");

  const auth = useAuth();
  const owner = useAuth().user.uid;
  const { toast } = useToast();

  const {
    data: documents,
    status: documentsStatus,
    error: documentsError,
  } = useDocumentsByOwner(auth.user.uid);


  const saveData = useCallback(
    debounce(async () => {
      try {
        setSaveState("inProgress"); 
        await saveDocumentData(editor.getJSON(), documentDetails.id, owner);
        setSaveState("completed");
      } catch (error) {
        console.error("Error saving document:", error);
        setSaveState("failed");
      }
    }, 500),
    [documentDetails.id, owner, toast, setSaveState] 
  );

  const { editor } = useBlockEditor(saveData); 


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const loadDocument = async (document_id) => {
    const data = await loadDocumentDetails(document_id);
    // if data is not null, set the document details
    if (data) setDocumentDetails(data);
  };

  const loadContext = async (document_id) => {
    const data = await loadDocumentContext(document_id);
    if (data) setDocumentContext(data);
  };

  function extractTextFromTipTapJson(tiptapJson) {
    let textContent = "";

    function traverseContent(content) {
      if (Array.isArray(content)) {
        content.forEach(traverseContent); 
      } else if (content.type === "text") {
        textContent += content.text;    
      } else if (content.content) {
        traverseContent(content.content); 
      }
    }

    traverseContent(tiptapJson.content);
    return textContent.trim();           
  }


  const loadContextDetails = async (document_id) => {

    const contextData = await loadDocumentContext(document_id);
    if (contextData) {

      let docDetails = {};
      let docContext = {};

      for (const context of contextData) {
        const data = await loadDocumentDetails(context.document_id);
        const docs = await loadDocumentData(context.document_id, owner);
        docDetails[context.document_id] = data;
        const parsedText = extractTextFromTipTapJson(docs);
        docContext[context.document_id] = parsedText;
      }
      console.log('setting context details', docDetails);
      setContextDetails(docDetails);
      console.log('setting context data', docContext)
      setContextData(docContext);

    }


  }

  useEffect(() => {
    loadDocument(documentDetails.id);
    loadContext(documentDetails.id);
    loadContextDetails(documentDetails.id);
    setSaveState("idle");
  }, [documentDetails.id]);

  const updateDocumentDetails = (field, newValue) => {
    setDocumentDetails((prevDetails) => ({
      ...prevDetails,
      [field]: newValue,
    }));
  };

  const handleDeleteContext = async (document_id) => {
    await deleteDocumentContext(document_id);
    setContextDetails(prevDetails => {
      const detailsArray = Object.values(prevDetails); // Convert to array
      const updatedDetails = detailsArray.filter(doc => doc.id !== document_id);
      return Object.fromEntries(updatedDetails.map(doc => [doc.id, doc])); // Convert back to object
    });

    //also remove this document ID from contextData
    setContextData(prevData => {
      const updatedData = { ...prevData };
      delete updatedData[document_id];
      return updatedData;
    });
  };

  const handleAddContext = async (selectedContext, goals) => {
    await createDocumentContext(selectedContext, documentDetails.id);
    updateDocument(documentDetails.id, 'goals', goals);
    loadContextDetails(documentDetails.id);

    //set documentdetails.goals to 'goals'

    setDocumentDetails((prevDetails) => ({
      ...prevDetails,
      goals: goals,
    }));

    // toast({
    //   title: "Scheduled: Catch up ",
    //   description: "Friday, February 10, 2023 at 5:57 PM",
    //   action: (
    //     <ToastAction altText="Goto schedule to undo">Undo</ToastAction>
    //   ),
    // })
  }

  return (
    <div className="h-screen bg-gray-100">
      <div className="flex h-screen">
        <CanvasContext.Provider value={contextDetails}>
          <DocumentsList
            documentDetails={documentDetails}
            updateDocumentDetails={updateDocumentDetails}
            setDocumentDetails={setDocumentDetails}
            auth={auth}
          />
          <DocumentPage
            editor={editor}
            documents={documents}
            documentDetails={documentDetails}
            updateDocumentDetails={updateDocumentDetails}
            setIsFileLoading={setIsFileLoading}
            handleAddContext={handleAddContext}
            saveState={saveState}
          />
          <ChatContext
            editor={editor}
            documentDetails={documentDetails}
            updateDocumentDetails={updateDocumentDetails}
            documentContext={documentContext}
            setDocumentContext={setDocumentContext}
            handleDeleteContext={handleDeleteContext}
            contextDetails={contextDetails}
            contextData={contextData}
          />
        </CanvasContext.Provider>
      </div>
    </div>
  );
};

export default requireAuth(CanvasPage);