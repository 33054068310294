import React from "react";
import "./../styles/global.css";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import DashboardPage from "../components/dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Routes, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import NotFoundPage from "./404";
import CanvasPage from "./canvas"
import Footer from "./../components/Footer";
import { analytics } from "./../util/analytics";
import { TrackPageViews } from "./../util/analytics";
import Chat from "./../components/Chat";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import { useLocation } from 'react-router-dom';
import { NavbarWrapper } from "./../components/NavbarWrapper";
import { Toaster } from "./../components/ui/toaster";
import { Toast } from "@radix-ui/react-toast";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Chat />
        <Router>
          <>
            <NavbarWrapper />
            <TrackPageViews />
            <Toaster />

            <Routes>
              <Route exact path="/" element={<IndexPage />} />

              {/* <Route exact path="/" element={<CanvasPage />} /> */}

              <Route exact path="/about" element={<AboutPage />} />

              <Route exact path="/faq" element={<FaqPage />} />

              <Route exact path="/contact" element={<ContactPage />} />

              <Route exact path="/pricing" element={<PricingPage />} />

              <Route exact path="/dashboard" element={<DashboardPage />} />

              <Route exact path="/auth/:type" element={<AuthPage />} />

              <Route exact path="/settings/:section" element={<SettingsPage />} />

              <Route exact path="/legal/:section" element={<LegalPage />} />

              <Route exact path="/purchase/:plan" element={<PurchasePage />} />

              <Route exact path="/canvas" element={<CanvasPage />} />


              <Route element={<NotFoundPage />} />
            </Routes>

            {/* <Footer
              size="md"
              bgColor="bg-white"
              bgImage=""
              bgImageOpacity={1}
              textColor=""
              sticky={true}
            /> */}
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
