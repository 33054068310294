import {
  useQuery,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "@tanstack/react-query";
import supabase from "./supabase";

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  return useQuery({
    queryKey: ["user", { uid }],
    queryFn: () =>
      supabase
        .from("users")
        .select(`*, customers ( * )`)
        .eq("id", uid)
        .single()
        .then(handle),
    enabled: !!uid,
  });
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return supabase
    .from("users")
    .select(`*, customers ( * )`)
    .eq("id", uid)
    .single()
    .then(handle);
}

// Update an existing user
export async function updateUser(uid, data) {
  const response = await supabase
    .from("users")
    .update(data)
    .eq("id", uid)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["user", { uid }]);
  return response;
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Fetch item data
export function useItem(id) {
  return useQuery({
    queryKey: ["item", { id }],
    queryFn: () => supabase.from("items").select().eq("id", id).single().then(handle),
    enabled: !!id,
  });
}


// Fetch all items by owner
export function useItemsByOwner(owner) {
  return useQuery({
    queryKey: ["items", { owner }],
    queryFn: () =>
      supabase
        .from("items")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    enabled: !!owner,
  });
}

// Create a new item
export async function createItem(data) {
  const response = await supabase.from("items").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["items"]);
  return response;
}


export async function createDocument(data) {
  const response = await supabase
    .from("documents")
    .insert([data])
    .select()
    .then(handle);

  await client.invalidateQueries(["documents"]);

  return response;
}

export async function saveDocumentData(json, document_id, owner) {
  const fileContent = JSON.stringify(json);

  const { data, error } = await supabase.storage
    .from("narrative-maker-storage")
    .upload(`${owner}/${document_id}`, fileContent, {
      contentType: "application/json",
      upsert: true,
    });
}

export async function loadDocumentData(document_id, owner) {
  try {
    const { data, error } = await supabase.storage
      .from("narrative-maker-storage")
      .download(`${owner}/${document_id}`);

    // Handle missing file
    if (!data) { 
      console.log("No content found for this document")
      const emptyTiptapDoc = {
        type: "doc",
        content: [],
      };
      return saveDocumentData(emptyTiptapDoc, document_id, owner) 
        .then(() => {
          console.log("new file created");
          return emptyTiptapDoc;
        })
        .catch((saveError) => {
          console.error("Error saving new document:", saveError);
        });
    }

    // Handle success
    const contentAsText = await data.text();
    const fileContent = JSON.parse(contentAsText);
    return fileContent;

  } catch (error) { 
    console.error("Error loading file content:", error);
    throw error; // Re-throw to allow for handling upstream
  }
}

export async function createDocumentContext(document_array, document_id) {
  const responses = [];
  for (const document of document_array) {
    const response = await supabase
      .from("document_contexts")
      .insert({ document_id: document, original_document: document_id })
      .select()
      .then(handle);

    responses.push(response);
  }

  await client.invalidateQueries(["document_contexts"]);

  return responses;
}

export async function deleteDocumentContext(document_id) {
  const response = await supabase
    .from("document_contexts")
    .delete()
    .eq("document_id", document_id)
    .then(handle);

  await client.invalidateQueries(["document_contexts"]);

  return response;
}

export async function loadDocumentContext(document_id) {
  try {
    const { data, error } = await supabase
      .from("document_contexts")
      .select()
      .eq("original_document", document_id)

    return data;
  } catch (error) {
    console.error("Error loading document context:", error);
  }
}

export async function loadDocumentDetails(document_id) {
  try {
    const { data, error } = await supabase
      .from("documents")
      .select()
      .eq("id", document_id)
      .maybeSingle();

    if (error) {
      console.error("Error loading document details:", error);
      return;
    }

    return data;
  } catch (error) {
    console.error("Error loading document details:", error);
  }
}

export function useDocumentsByOwner(owner) {
  return useQuery({
    queryKey: ["documents", { owner }],
    queryFn: () =>
      supabase
        .from("documents")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    enabled: !!owner,
  });
}

// Update an item
export async function updateItem(id, data) {
  const response = await supabase
    .from("items")
    .update(data)
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["item", { id }]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}

// Update an item
export async function updateDocument(id, type, data) {
  const response = await supabase
    .from("documents")
    .update({ [type]: data })
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["document", { id }]),
    client.invalidateQueries(["documents"]),
  ]);
  return response;
}

// Delete an item
export async function deleteItem(id) {
  const response = await supabase
    .from("items")
    .delete()
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["item", { id }]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}

// Delete an item
export async function deleteDocument(id) {
  const response = await supabase
    .from("documents")
    .delete()
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["document", { id }]),
    client.invalidateQueries(["documents"]),
  ]);
  return response;
}

/**** HELPERS ****/

// Get response data or throw error if there is one
function handle(response) {
  if (response.error) throw response.error;
  return response.data;
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
