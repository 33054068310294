import React, { useState, useEffect, useCallback, useContext } from "react";
import { debounce } from "lodash";
import { updateDocument } from "../util/db";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "./ui/sheet"
import { Button } from './ui/button'
import { Textarea } from './ui/textarea'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from './ui/card'
import { deleteDocumentContext } from '../util/db'
import { CanvasContext } from "./CanvasContext";

function SideMenu(props) {

    const handleDeleteContext = props.handleDeleteContext;

    const [goals, setGoals] = useState(props.documentDetails.goals)
    const updateDocumentDetails = props.updateDocumentDetails;
    const documentDetails = props.documentDetails;
    const contextDetails = useContext(CanvasContext);
    const [refreshTrigger, setRefreshTrigger] = useState(0);

    const handleRefresh = () => {
        setRefreshTrigger(prevTrigger => prevTrigger + 1);
      };

    const handleDelete = (document_id) => {
        handleDeleteContext(document_id);
        handleRefresh();
    }


    const debouncedUpdate = useCallback(debounce((goal) => {
        updateDocument(props.documentDetails.id, 'goals', goal);
        updateDocumentDetails('goals', goal)
    }, 300), [props.documentDetails.id]);

    function handleUpdateGoal(goal) {
        setGoals(goal);
        debouncedUpdate(goal);
    }

    useEffect(() => {
        setGoals(documentDetails.goals);
        handleRefresh()
    }, [documentDetails.id, documentDetails.goals, contextDetails]);

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button onClick={() => handleRefresh()} className="text-sm"> Edit context </Button>
            </SheetTrigger>
            <SheetContent className="h-screen">
                <SheetHeader>
                    <SheetTitle>Context</SheetTitle>
                    <SheetDescription>
                        Add, update or edit context.
                    </SheetDescription>
                </SheetHeader>

                <br className="mt-15" />

                <Textarea
                    placeholder="Write down the goals of this document"
                    value={goals}
                    onChange={e => handleUpdateGoal(e.target.value)} />

                <br className="mt-10" />
                {contextDetails && (
                    <div className="grid grid-cols-2 gap-4 mb-10">
                        {Object.entries(contextDetails).map(([key, document], index) => (
                            <Card key={key}>
                                <CardHeader>
                                    <CardTitle>{document.name}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p>{document.goals || 'No goal'}</p>
                                </CardContent>
                                <CardFooter className="flex">
                                    <Button onClick={() => handleDelete(document.id)} variant="outline">Remove</Button>
                                </CardFooter>
                            </Card>
                        ))}
                    </div>
                )}


                <SheetFooter className="sticky bottom-0">
                    <SheetClose asChild>
                        <Button type="submit">Close</Button>
                    </SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>

    )
}

export default SideMenu
