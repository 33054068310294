import React, { useEffect, useState, useCallback, useContext } from "react";
import { EditorContent, BubbleMenu } from "@tiptap/react";
import { loadDocumentData } from "../util/db";
import "./DocumentPage.css";
import { useAuth } from "../util/auth";
import { debounce } from "lodash";
import { updateDocument } from "../util/db";
import { getGroqTextAdaptation } from "../util/groqUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSync,
  faCompress,
  faExpand,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import InitialContext from "./InitialContext";
import { CanvasContext } from "./CanvasContext";
import { Badge } from "./ui/badge";

function DocumentPage(props) {
  const [documentName, setDocumentName] = useState("");
  const [prompt, setPrompt] = useState("");

  const documentDetails = props.documentDetails;
  const updateDocumentDetails = props.updateDocumentDetails;
  const setIsFileLoading = props.setIsFileLoading;
  const documentContext = props.documentContext;
  const handleAddContext = props.handleAddContext;
  const contextDetails = useContext(CanvasContext)
  const saveState= props.saveState

  const owner = useAuth().user.uid;

  const editor = props.editor

  const debouncedUpdate = useCallback(
    debounce((name) => {
      updateDocument(documentDetails.id, "name", name);
      updateDocumentDetails("name", name);
    }, 300),
    [documentDetails.id],
  );

  function handleUpdateName(name) {
    if (name.trim().length === 0) {
      name = "Untitled document"; // Set a default name
    }
    setDocumentName(name);
    debouncedUpdate(name);
  }


  const loadFileContent = async () => {
    if (!documentDetails) return;

    setIsFileLoading(true);

    if (editor) {
      try {
        const newContent = await loadDocumentData(documentDetails.id, owner);
        editor.commands.setContent(newContent, false);
      } catch (error) {
        if (error.message === "Object not found") {
          console.log("No content found for this document");
        }
        console.error("Error loading file content:", error);
      } finally {
        setIsFileLoading(false);
      }
    }
  };

  const inlineGenerate = (actionType, prompt) => {

    const { view, state } = editor;
    const { from, to } = view.state.selection;
    const text = state.doc.textBetween(from, to, "");

    console.log('prompt:', prompt)

    getGroqTextAdaptation(actionType, text, prompt, editor.getText())
      .then((response) => {
        if (response) {
          editor
            .chain()
            .focus()
            .insertContentAt(
              { from: from, to: to },
              response,
            )
            .run();
        }
      })
      .catch((error) => {
        console.error("Error fetching chat completion:", error);
      });
    setPrompt("");
    return;
  };

  useEffect(() => {
    console.log('re-rendering document page')
    loadFileContent();
    setDocumentName(documentDetails.name || "Untitled document");
  }, [documentDetails.id, editor, documentDetails.name]);

  return (
    <div className="w-3/6 h-screen overflow-y-scroll pl-4 pr-4 pt-4">
      <div className="min-h-screen w-full rounded-t-xl bg-white p-10 shadow-md ">

        <div className="flex justify-between items-center relative">
          <input
            type="text"
            className="border-none w-full pl-0 text-2xl font-bold  focus:outline-white"
            placeholder="Document title"
            value={documentName}
            onChange={(e) => {
              handleUpdateName(e.target.value);
            }}
          />
          <div className="absolute top-0 right-0">
      {saveState === "inProgress" ? (
        <Badge variant="outline">Saving...</Badge>
      ) : saveState === "completed" ? (
        <Badge variant="secondary">Saved</Badge>
      ) : saveState === "failed" ? (
        <Badge variant="destructive">Document not saved</Badge>
      ) : null /* No badge if idle */}
    </div>
        </div>

        <div className="pt-10">

          {editor && (
            <BubbleMenu editor={editor} tippyOptions={{ duration: 1 }}>
              <div className="rounded-xl bg-white p-2 shadow">
                <div className="rounded-xl bg-gray-100 p-1">
                  <input
                    type="text"
                    className="text-l rounded-xl border-none bg-gray-100 pl-2 focus:outline-white"
                    placeholder="Add prompt"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                  />
                </div>
                <div className="m-2 flex flex-col justify-between space-y-3">
                  <button
                    onClick={() => inlineGenerate("modify", prompt)}
                    className="text-left"
                  >
                    <FontAwesomeIcon icon={faEdit} className="mr-2" />
                    Modify
                  </button>
                  <button
                    onClick={() => inlineGenerate("regenerate", prompt)}
                    className="text-left"
                  >
                    <FontAwesomeIcon icon={faSync} className="mr-2" />
                    Regenerate
                  </button>
                  <button
                    onClick={() => inlineGenerate("shorter", prompt)}
                    className="text-left"
                  >
                    <FontAwesomeIcon icon={faCompress} className="mr-2" />
                    Shorter
                  </button>
                  <button
                    onClick={() => inlineGenerate("longer", prompt)}
                    className="text-left"
                  >
                    <FontAwesomeIcon icon={faExpand} className="mr-2" />
                    Longer
                  </button>
                  <button
                    onClick={() => inlineGenerate("remove", prompt)}
                    className="text-left"
                  >
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    Remove
                  </button>
                </div>
              </div>
            </BubbleMenu>
          )}

          <EditorContent editor={editor} />

          {editor && editor.isEmpty && Object.keys(contextDetails).length === 0 && documentDetails.goals == "" && (
            <div className="p4">
              <InitialContext
                documents={props.documents}
                documentDetails={documentDetails}
                documentContext={documentContext}
                handleAddContext={handleAddContext} />
            </div>
          )}

        </div>

      </div>
    </div>
  );
}

export default DocumentPage;
