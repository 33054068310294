import React, { useState, useEffect } from 'react'
import { Textarea } from './ui/textarea'
import { Separator } from './ui/separator'
import { Button } from './ui/button'


function InitialContext({ documents, documentDetails, documentContext, handleAddContext }) {

    const [selectedContext, setSelectedContext] = useState([]);
    const [goals, setGoals] = useState('')

    useEffect(() => {
        setGoals(documentDetails.goals);
    }
        , [documentDetails.id, documentContext])

    return (
        <div className="pt-10">
            <Separator />

            <h3 className="text-lg font-bold text-gray-700 mt-5 mb-3"> I'm writing to... </h3>

            <Textarea placeholder="Write down the goals of this document"
                value={goals}
                onChange={(e) => setGoals(e.target.value)} />


            <Separator className="mt-10" />

            <h3 className="text-lg font-bold text-gray-700 mt-8 mb-3"> It's based on... </h3>


            {documents && documents.length > 0 && (
                <ul className="grid grid-cols-2 gap-4 overflow-y-scroll [&::-webkit-scrollbar]:hidden mb-10">
                    {documents && documents.filter(doc => doc.id !== documentDetails.id).map((document, index) => (
                        <div
                            className={`shadow-sm max-h-60 h-40 overflow-hidden p-4 flex flex-col justify-start items-start border border-gray-300 cursor-pointer rounded-xl hover:bg-blue-100 ${selectedContext.includes(document.id) ? 'bg-blue-200' : ''} `}
                            onClick={() => {
                                if (selectedContext.includes(document.id)) {
                                    setSelectedContext(selectedContext.filter(id => id !== document.id));
                                } else {
                                    setSelectedContext([...selectedContext, document.id]);
                                }
                            }}
                        >
                            <h2 className="text-lg font-bold text-gray-700">{document.name}</h2>
                            <p className="pt-1 text-sm text-gray-700 overflow-ellipsis overflow-hidden"> {document.goals} </p>
                        </div>
                    ))}
                </ul>
            )}

            <br />
            {(goals !== '' || selectedContext.length > 0) && (
                <Button onClick={() => handleAddContext(selectedContext, goals)} className="sticky bottom-8 w-full py-2 text-white text-center">
                    Add context to document
                </Button>
            )}
        </div>
    )
}

export default InitialContext
