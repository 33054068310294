import groq from "./groq";

export const getGroqChatCompletionStreaming = async(messageHistory) => {
  console.log('streaming')
  try {
    return groq.chat.completions.create({
      messages: messageHistory,
      model: "llama3-70b-8192",
      stream: true 
    });

  } catch (error) {
    return
  }
}

export const getGroqChatCompletion = async (messageHistory) => {
  try {
    const response = await groq.chat.completions.create({
      messages: messageHistory,
      model: "llama3-70b-8192",
    });

    const messageContent = response.choices[0].message.content;
    return messageContent;
  } catch (error) {
    console.error("Error fetching Groq chat completion:", error);
    return null; // Or return an error indicator of your choice
  }
};

export const getGroqTextAdaptation = async (
  actionType,
  initialText,
  prompt,
  fileContents
) => {
  // there's 4 different action types and I want a prompt each

  const basePrompt =
    "You're an AI writing assistant designed to help product managers. Your aim is to provide efficient, knowledgeable, and adaptable support. Please respond using markdown to only the requested task and only provide a replacement for the initial text that can drop right in to the document. The text you provide should slot in directly to the existing doc in a seamless way. \n";

  const promptType = {
    modify: "Modify the following text to strengthen the argument",
    regenerate: "Regenerate the followin text to improve clarity",
    shorter: "Make this text shorter, while capturing the main idea",
    longer: "Expand on this text to provide more context",
    remove: "Remove unnecessary information from this text",
  };

  const initialTextPrompt =
    "\n Here's the text you need to adapt: \n" + initialText;

  const additionalPrompt = "\n Here's some additional requests (may be empty): \n" + prompt;

  const restOfDocument = "Here's the rest of the document: \n" + fileContents;

  const finalPrompt =
    basePrompt +
    "\n" +
    promptType[actionType] +
    initialTextPrompt +
    additionalPrompt +
    restOfDocument;

  console.log("finalPrompt:", finalPrompt);
  const groqMessageAdaptation = [{ role: "system", content: finalPrompt }];

  try {
    const response = await groq.chat.completions.create({
      messages: groqMessageAdaptation,
      model: "llama3-70b-8192",
    });

    const messageContent = response.choices[0].message.content;
    return messageContent;
  } catch (error) {
    console.error("Error fetching Groq text adaptation:", error);
    return null;
  }
};
