import Analytics from "analytics";
import mixpanelPlugin from "@analytics/mixpanel";
import { useLocation } from "react-router-dom"; 
import React, { useEffect } from "react";


export const analytics = Analytics({
  debug: process.env.NODE_ENV !== "production",
  plugins: [
    mixpanelPlugin({
      token: process.env.REACT_APP_MIXPANEL_TOKEN,
    }),
  ],
});

// Track initial pageview
if (typeof window !== "undefined") {
  analytics.page();
}

export function TrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    analytics.page();
  }, [location]);

  return null;
}