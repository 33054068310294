import React, { useState, useEffect, useRef, useContext } from "react";
import { getGroqChatCompletion, getGroqChatCompletionStreaming } from "../util/groqUtils";
import SuggestedPrompts from "./SuggestedPrompts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faCopy } from "@fortawesome/free-solid-svg-icons";
import * as showdown from "showdown";
import DOMPurify from "dompurify";
import { Textarea } from "./ui/textarea";
import { Button } from "./ui/button";
import { Send } from 'lucide-react';
import "./ChatArea.css";
import { CanvasContext } from "./CanvasContext";

function ChatArea(props) {
  const [messages, setMessages] = useState([]);
  const [currentText, setCurrentText] = useState("");
  const [streamingMessage, setStreamingMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const contextDetails = useContext(CanvasContext)



  const converter = new showdown.Converter();
  const context = props.context;
  const contextData = props.contextData;
  const documentDetails = props.documentDetails;
  const editor = props.editor;

  const canvasContent = editor?.getText();

  const chatContainerRef = useRef(null);
  const modalRef = useRef(null);


  const handleClick = () => {
    if (currentText.trim() !== "") {
      const newMessage = {
        role: "user",
        content: currentText, // Determine the appropriate sender here
      };

      setMessages((prevState) => [...prevState, newMessage]);
      setCurrentText("");
    }
  };

  const handleMessageResponse = () => {
    const initialMessage = `**LLM Briefing:** You're an AI writing assistant designed to help product managers. Your aim is to provide efficient, knowledgeable, and adaptable support. Please respond only using markdown. Don't hesitate to ask the user clarifying questions if anything is underspecified. Remember these principles when crafting responses for the user:

    * **Helpful:**  Offer actionable suggestions that directly address the task at hand.
    * **Knowledgeable:** Demonstrate your understanding of product management concepts.
    * **Efficient:** Save the product manager time by handling drafts and revisions.
    * **Adaptable:** Adjust your writing style to suit the product manager's needs.
    
    **Current User Goal:**  ${context}
    **Current Document Content:** ${canvasContent}
            `;

    // if the first message in the message array is not role: system, prepend the context
    const messagesWithInitial = [
      { role: "system", content: initialMessage },
      ...messages,
    ];

    getGroqChatCompletion(messagesWithInitial)
      .then((response) => {
        if (response) {
          const newMessage = {
            role: "assistant",
            content: response, // Determine the appropriate sender here
          };
          setMessages((prevState) => [...prevState, newMessage]);
          scrollToBottom();
        }
      })
      .catch((error) => {
        console.error("Error fetching chat completion:", error);
      });
  };

  function updateLastObjectContent(messages, newContent) {
    const messagesCopy = [...messages]; // Create a copy of the array

    // Find the index of the last object 
    const lastObjectIndex = messagesCopy.length - 1;

    // Update the content property of the last object
    if (lastObjectIndex !== -1) {
      messagesCopy[lastObjectIndex].content += newContent; // Append to existing content
    }

    return messagesCopy;
  }

  const initialMessage = `You're an AI writing assistant designed to help product managers. Your aim is to provide efficient, knowledgeable, and adaptable support. Please respond only using markdown. Don't hesitate to ask the user clarifying questions if anything is underspecified. Remember these principles when crafting responses for the user:

  * **Helpful:**  Offer actionable suggestions that directly address the task at hand.
  * **Knowledgeable:** Demonstrate your understanding of product management concepts.
  * **Efficient:** Save the product manager time by handling drafts and revisions.
  * **Adaptable:** Adjust your writing style to suit the product manager's needs.

**Current Document Goal:**  ${context}

**What's written currently in the document:** 

\`\`\`
${canvasContent}
\`\`\`

**Relevant documents for other context:**

${Object.entries(contextData).map(([docId, docContent]) => `
### Document name: ${contextDetails[docId]?.name}

\`\`\`
${docContent}
\`\`\`
`).join('')}
`;
  const history = [
    {
      role: "user",
      parts: [{ text: initialMessage}],
    },
    {
      role: "model",
      parts: [{ text: "Great, let's get started!"}],
    },
  ];

  async function handleMessageResponseStreamingGemini() {

  
    const messagesWithInitial = [
      { role: "user", contents: [{ text: initialMessage }] }, // System message
      ...messages.map(msg => ({ role: msg.role, contents: [{ text: msg.content }] })), // User messages
    ];

    console.log('messages', messages);

    //message is last message in messages from user

    const query = {
      message: messages[messages.length -1].content,
      history: [
        ...(history || []),
        ...messages.slice(0, -1).map(msg => {
          if (typeof msg.content !== 'string') {
            console.error('Invalid message content:', msg);
            return null;  
          }
          return { role: msg.role, parts: [{ text: msg.content }] };
        }).filter(Boolean) 
      ]
    }
    
    setIsLoading(true);
  
    try {
      const response = await fetch('https://email-buddy.com/api', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(query),
      });

      console.log('response', response)
  
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
  
      const newMessage = { role: "assistant", content: "" };
      setMessages(prevState => [...prevState, newMessage]);
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";  // Buffer for partial JSON data

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
  
        const chunkText = decoder.decode(value);
        buffer += chunkText;
  
        // Split into JSON objects and process each
        const jsonObjects = buffer.split('}{').map(str => {
          if (!str.startsWith('{')) str = '{' + str;
          if (!str.endsWith('}')) str = str + '}';
          return str;
        });
  
        for (const jsonObject of jsonObjects) {
          try {
            const chunkData = JSON.parse(jsonObject);
  
            // Check if 'text' property exists before accessing
            if (chunkData.text !== undefined) {
              const text = chunkData.text;
              setMessages(prevState => updateLastObjectContent(prevState, text));
            } else {
              console.warn('Received chunk without "text" property:', chunkData);
            }
  
            if (jsonObject === jsonObjects[jsonObjects.length - 1]) {
              buffer = ""; 
            }
          } catch (parseError) {
            console.error("Error parsing chunk:", parseError, jsonObject);
  
            // Handle incomplete JSON
            const lastBracketIndex = jsonObject.lastIndexOf("}");
            if (lastBracketIndex > -1) {
              buffer = jsonObject.slice(lastBracketIndex + 1);
            } else {
              buffer = jsonObject;
            }
            break; // Exit loop and wait for more data
          }
        }
      }
    } catch (error) {
      console.error("Error fetching chat completion:", error);
      setMessages(prevState => [...prevState, {
        role: "assistant",
        content: 'An error occurred while processing your request. Please try again.'
      }]);
    } finally {
      setIsLoading(false);
    }
  }
  

  const handleMessageResponseStreaming = async () => {
    const initialMessage = `**LLM Briefing:** You're an AI writing assistant designed to help product managers. Your aim is to provide efficient, knowledgeable, and adaptable support. Please respond only using markdown. Don't hesitate to ask the user clarifying questions if anything is underspecified. Remember these principles when crafting responses for the user:

  * **Helpful:**  Offer actionable suggestions that directly address the task at hand.
  * **Knowledgeable:** Demonstrate your understanding of product management concepts.
  * **Efficient:** Save the product manager time by handling drafts and revisions.
  * **Adaptable:** Adjust your writing style to suit the product manager's needs.
  
  **Current User Goal:**  ${context}
  **Current Document Content:** ${canvasContent}
  **Relevant context from other documents** ${JSON.stringify(contextData)}

          `;

    console.log('initial message', initialMessage)

    const messagesWithInitial = [
      { role: "system", content: initialMessage },
      ...messages,
    ];

    setIsLoading(true);

    try {
      const stream = await getGroqChatCompletionStreaming(messagesWithInitial);

      const newMessage = {
        role: "assistant",
        content: "",
      };

      setMessages((prevState) => [...prevState, newMessage]);

      for await (const chunk of stream) {
        if (chunk.choices[0]?.finish_reason != "stop") {
          setMessages(prevState => updateLastObjectContent(prevState, chunk.choices[0]?.delta?.content));
        }
      }
    } catch (error) {
      console.error("Error fetching chat completion:", error);
      setMessages(prevState => [...prevState, {
        role: "assistant",
        content: 'An error occurred while processing your request. Please try again.'
      }])
      scrollToBottom();
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };

  const handlePromptClick = (promptText) => {
    const newMessage = {
      role: "user",
      content: promptText,
    };
    setMessages((prevState) => [...prevState, newMessage]);
  };

  useEffect(() => {
    const handleMouseUp = () => {
      const selection = window.getSelection();
      const text = selection.toString();
      if (text && chatContainerRef.current.contains(selection.anchorNode)) {
        setSelectedText(text);
        const rect = selection.getRangeAt(0).getBoundingClientRect();
        setModalPosition({ top: rect.bottom, left: rect.right }); // Position the modal at the bottom right of the selected text
        setIsModalVisible(true);
      }
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalVisible(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    handleClear();
  }, [documentDetails.id]);

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].role === "user") {
      //handleMessageResponse();
      handleMessageResponseStreamingGemini();
    }
    handleClick();
  }, [messages]);

  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Check for 'Enter' without 'Shift'
      e.preventDefault(); // Prevent default newline behavior
      handleClick(); // Call the existing submission logic
    }
  };

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const handleClear = () => {
    setMessages([]);
  };

  const handleInsertClick = (text) => {
    const currentPosition = editor.view.state.selection.from;
    editor.commands.insertContentAt(currentPosition, text);
  };

  const suggestedPrompts = [
    "Define our target market and customer personas",
    "Create a product roadmap for next quarter",
    "Draft a product update email for stakeholders",
    "Analyze customer feedback from our latest survey",
    "Develop a communication plan for a product launch",
    "Review the latest user engagement metrics",
  ];

  return (
    <div id="container" class="flex flex-col flex-grow relative">
      <div className="flex flex-col flex-grow">
        <p className="ml-2" onClick={handleClear}> Clear chat </p>
        <div id="message-area" class="flex-grow overflow-y-scroll p-4 max-h-[calc(100vh-theme('spacing.16')-theme('spacing.24'))]">
          <ul className="overflow-y-auto w-full" ref={chatContainerRef}>
            {messages.map((msg, index) => (
              <li
                key={index}
                className={"chat-message mb-2"}
              >
                <b>{msg.role}</b>{" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(converter.makeHtml(msg.content)),
                  }}
                />
                <p>{streamingMessage}</p>
                {msg.role === "assistant" && (
                  <div>
                    <button
                      className="bottom-2 right-2 rounded-full p-1"
                      onClick={() => handleCopy(msg.content)}
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                    <button onClick={() => handleInsertClick(msg.content)}>
                      <FontAwesomeIcon icon={faFileImport} />
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div id="message-box" class="absolute bottom-0 w-full p-4 flex items-center min-h-0">
          <div className="fixed bottom-28 w-fit pr-8">
            {messages.length === 0 && (
              <SuggestedPrompts
                handlePromptClick={handlePromptClick}
                prompts={suggestedPrompts}
              />
            )}
          </div>
          <Textarea
            className="h-12 w-full flex-none rounded-xl pr-12 pt-3 outline-none bg-white"
            value={currentText} // Bind the textarea value
            onChange={(e) => setCurrentText(e.target.value)}
            onKeyDown={(e) => handleKeyPress(e)}
          ></Textarea>
          <Button
            variant="primary"
            size="icon"
            onClick={handleClick}
            className="absolute right-6 bottom-6 flex h-10 w-10 items-center rounded-full"
          >
            <Send
              className="h-4 w-4 fill-current text-black"
              src="/img/arrow-up-bold-circle.svg"
              alt="Arrow up icon"
            />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ChatArea;
