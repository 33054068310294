import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
  title="One Price, Limitless Possibilities"
  subtitle="Unlock the full potential of our LLM copilot and transform your product management workflows. Effortless clarity, persuasive writing, and efficient collaboration - all in one premium solution."
  strapline="Simple, Transparent Pricing"
  size="md"
  bgColor="bg-white"
  bgImage=""
  bgImageOpacity={1}
  textColor=""
/>

    </>
  );
}

export default PricingPage;
