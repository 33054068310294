import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';


export function NavbarWrapper() {
    const location = useLocation();
  
    if (location.pathname === '/canvas') {
      return null;
    }
  
    return <Navbar bgColor="bg-white" />;
  }