import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";

function HeroSection(props) {
  return (
  <Section 
    size={props.size} 
    bgColor={props.bgColor} 
    bgImage={props.bgImage} 
    bgImageOpacity={props.bgImageOpacity} 
    textColor={props.textColor} 
  >
    <div className="container flex flex-col space-y-3 lg:space-y-0 text-center">
      {/* Text Content */}
      <div className="lg:self-center">
        <SectionHeader 
          title={props.title} 
          subtitle={props.subtitle} 
          strapline={props.strapline} 
          className="text-4xl font-serif lg:max-w-2xl" // Add your desired font and size here
        />
        <div className="flex flex-col sm:flex-row sm:items-center justify-center space-y-2 sm:space-y-0 sm:space-x-2 pt-10 pb-16">
          {/* Apply for beta Button */}
          <Button 
            component={Link} 
            to="https://email-solution.ck.page/e8d6e962f6" 
            size="xl" 
            className="w-full rounded-full sm:w-auto self-center" // Add classes for width and vertical alignment
          >
            Apply for beta
          </Button> 
        </div>
      </div>
    </div>
  </Section>
);
}

export default HeroSection;
